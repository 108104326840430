import React from 'react'
import { useHistory } from 'react-router-dom'
import ActionButton from './ActionButton'
import './PageFooter.scss'

export default function () {
  return null
  const history = useHistory()
  return (
    <section className='callout action-wrpr page-footer'>
      <div className='cap-width'>
        <h2>Tell key decisionmakers:</h2>
        <h3>We need a cabinet free from corporate interests.</h3>
        <div className='page-footer-actions'>
          <ActionButton icon='sign' onClick={() => window.location.pathname = '/sign'}>
            Sign the Petition
          </ActionButton>
          <ActionButton icon='phone' onClick={() => window.location.pathname = '/call'}>
            Make A Call
          </ActionButton>
          <ActionButton icon='twitter' onClick={() => window.location.pathname = '/social'}>
            Tweet at Decisionmakers
          </ActionButton>
        </div>
      </div>
    </section>
  )
}