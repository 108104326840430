import React from 'react'
import './PartnerLogos.scss'

export default function ({ site }) {
  const logos = site.partner_logos || []
  if (logos.length < 1) return null
  return (
    <section id='partners'>
      <div className='cap-width'>
        <div className='logos'>
          {
            logos.filter(x => x.logo).map(x => <a href={x.url || '/'} target='_blank' style={{ backgroundImage: `url(${x.logo})` }} key={x.logo} />)
          }
        </div>
      </div>
    </section>
  )
}
