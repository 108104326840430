import React from 'react'
import { Link } from 'react-router-dom'
import { getHeadshotForSize, getPersonLink } from '../util'
import './Person.scss'

export default function ({ person }) {
  const url = getPersonLink(person)
  const isRemote = url.match(/^https?:\/\//)

  const style = {}
  const headshot = getHeadshotForSize(person, 'medium_large')
  if (headshot) style.backgroundImage = `url(${headshot})`
  style.backgroundPosition = 'center'

  const content = (
    <>
      <span className='img' style={style}></span>
      <span className='name'><span>{person.name}</span></span>
    </>
  )

  return (
    <li>
      {
        isRemote
          ? <a href={url} className='box'>{content}</a>
          : <Link to={url} className='box'>{content}</Link>
      }
      <span className='descr'>{person.thumbnail_description}</span>
    </li>
  )
}
