import React, { useState } from 'react'
import './MainPage.scss'
import { Link, useParams, useHistory } from 'react-router-dom'
import SafeSpan from './SafeSpan'
import Sidebar from './SideBar'
import PartnerLogos from './PartnerLogos'
import Person from './Person'
import Headshot from './Headshot'
import PageFooter from './PageFooter'
import { alphaSort, slugify } from '../api'
import { prop } from 'ramda'
import cn from 'classnames'

export default function MainPage ({ site }) {
  const [zip, setZip] = useState('')
  const [step, setStep] = useState('')
  const { name } = useParams()

  let show = null
  if (site.loaded) {
    let theSite = site
    if (name) {
      theSite = site.peopleSites.find(s => name === slugify(s.name))
    }
    let sidebar = null
    if (theSite.actionkit_slug) {
      sidebar = (
        <Sidebar
          site={theSite}
          zip={zip}
          setZip={setZip}
          onChange={setStep}
        />
      )
    }
    const underContent = []
    if (theSite.umbrella) {
      const features = site.peopleSites.filter(prop('feature'))
      if (features.length > 0) {
        underContent.push(<div key='people-wrap' className='people-wrap'>
          <div className='cap-width'>
            <h2>Here are some of the most concerning corporate insiders trying to get into the Trump administration:</h2>
            <ul className='people'>
              {features.sort(alphaSort).map(p => <Person person={p} key={p.name} />)}
            </ul>
            <Link className='right-link' to='/persons-of-interest'>See all Persons of Interest ></Link>
          </div>
        </div>)
      }
      if (theSite.hp_under_poi !== '') {
        underContent.push(<div key='under_poi' className='hp-news'>
          <div className='cap-width'>
            <SafeSpan content={theSite.hp_under_poi} />
            <Link className='right-link' to='/news'>See all News ></Link>
          </div>
        </div>)
      }
    }
    show = <>
      <div className={cn('main-page', step)} id='content'>
        <div className='cap-width'>
          <section id='intro'>
            <div className='text-intro'>
              <header>
                <h2>{theSite.subtitle}</h2>
                <h1><SafeSpan content={theSite.title} /></h1>
              </header>
            </div>
            {sidebar}
          </section>
        </div>
        <article className='post texture-text'>
          <div className='cap-width'>
            <SafeSpan className='site-content' content={theSite.content} />
          </div>
          {underContent}
        </article>
        <PageFooter />
        <PartnerLogos site={theSite} />
      </div>
      <Headshot site={theSite} />
    </>
  }
  return show
}