import React, { useEffect, useState } from 'react'
import Person from './Person'
import PageFooter from './PageFooter'
import SafeSpan from './SafeSpan'
import './POI.scss'
import { alphaSort, API_URL } from '../api'
import { prop } from 'ramda'


export default function POI ({ site }) {
  const [content, setContent] = useState({})
  useEffect(() => {
    fetch(API_URL + '/wp-json/wp/v2/pages/877')
      .then(res => res.json())
      .then(res => {
        setContent({
          content: res.content.rendered,
          title: res.title.rendered
        })
      })
  }, [])
  if (!site.loaded) return null
  console.log(content);
  return (
    <div id='content'>
      <section id='intro'>
        <div className='text-intro cap-width'>
          <header>
            <h1><SafeSpan content={content.title} /></h1>
          </header>
        </div>
      </section>
      <article className='texture-text'>
        <div className='cap-width'>
          {content.content !== '' ? <div className='page-content'><SafeSpan content={content.content} /></div> : null}
          <ul className='people'>
            {site.peopleSites
              .filter(prop('feature'))
              .sort((a, b) => {
                if (a.feature && !b.feature) return -1
                if (b.feature && !a.feature) return 1
                return alphaSort(a, b)
              })
              .map(p => <Person person={p} key={p.name} />)
            }
          </ul>
        </div>
      </article>
      <PageFooter />
    </div>
  )
}
